export function courseContentStreamingTableHead () {
  return [
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Date at',
      value: 'date_at'
    },
    {
      text: 'Packages',
      value: 'packages'
    },

    {
      text: 'Status',
      value: 'status'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}
