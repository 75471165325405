import { mapActions, mapGetters, mapMutations } from 'vuex'

import mixins from '@/mixins'

import DialogView from '../dialog-view/index.vue'
import Draggable from 'vuedraggable'

import { courseContentStreamingTableHead } from '@/modules/courses/helpers/content-streaming'

export default {
  name: 'courses-contents-streaming-main',
  mixin: [mixins],
  data () {
    return {
      showRemoveDialog: false,
      removingItem: null,
      openDialogView: false,
      editedItem: null,
      streams: []
    }
  },
  components: {
    DialogView,
    Draggable
  },
  watch: {
    'list' () {
      this.streams = this.list
    }
  },
  computed: {
    ...mapGetters({
      list: 'contentStreaming/list',
      isListLoading: 'contentStreaming/isListLoading',
      isLoading: 'contentStreaming/isLoading'
    }),
    courseContentStreamingTableHead
  },
  created () {
    if (!this.list.length) {
      this.fetchList({ course_id: this.$route.params.course_id }).then(() => {
        this.streams = this.list
      })
    } else {
      this.streams = this.list
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'contentStreaming/GET_LIST',
      remove: 'contentStreaming/DELETE'
    }),
    ...mapMutations({
      setCurrentItem: 'contentStreaming/SET_ITEM'
    }),
    closeDialogView () {
      setTimeout(() => {
        this.openDialogView = false
      }, 500)
    },
    changeDialogView (item = null) {
      this.setCurrentItem(item)
      this.openDialogView = true
    },
    removeStream () {
      this.remove(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    changeDialogDeleteItem (item) {
      this.showRemoveDialog = true

      item.course_id = this.$route.params.course_id
      this.removingItem = item
    }
  }
}
