import { mapActions, mapGetters } from 'vuex'
import { camelCase, debounce, snakeCase } from 'lodash'
import moment from 'moment'

import mixins from '@/mixins'
import validation from '@/mixins/validation'
import course from '@/mixins/course'

import MainEditor from '@/components/editor/index.vue'
import ImageFile from '@/components/image-file/index.vue'

import SelectPackages from '@/components/forms/SelectPackages'
import SelectUserPosition from '@/components/select-user-position';

export default {
  name: 'courses-contents-streaming-general',
  mixin: [course, mixins, validation],
  data () {
    return {
      valid: false,
      search: null,
      searchInput: null,
      payload: {
        name: '',
        description: '',
        player: '',
        comments: '',
        packages: '',
        picture: '',
        statusId: '',
        speakers: [],
        hide_comments: false
      }
    }
  },
  components: {
    MainEditor,
    ImageFile,
    SelectPackages,
    SelectUserPosition
  },
  computed: {
    ...mapGetters({
      courseInfo: 'coursesInfo/info',
      content: 'contentStreaming/content',
      isLoading: 'contentStreaming/isLoading',
      packagesList: 'coursesPackages/list',
      currentCourse: 'coursesInfo/info',
      setting: 'courses/setting',
      currentItem: 'contentStreaming/currentItem',
      speakers: 'user/speakers',
      speakersLoading: 'user/speakersLoading',
      updateImageLoading: 'contentStreaming/updateImageLoading'
    })
  },
  created () {
    if (this.currentItem) {
      this.setData()
      this.fetchSpeakers({isAuthors: false,}).then(() => {
        this.setData()
      })
    } else {
      this.fetchSpeakers({isAuthors: false,})
    }

    this.$on('event-save', () => {
      this.submit()
    })
  },
  methods: {
    ...mapActions({
      update: 'contentStreaming/UPDATE',
      create: 'contentStreaming/CREATE',
      fetchSpeakers: 'user/GET_SPEAKERS',
      updateImage: 'contentStreaming/UPDATE_IMAGE'
    }),

    onReloadUserPosition(){
        this.fetchSpeakers({
            isAuthors: false,
        })
    },

    updateImageLocal (params) {
      params.append('course_id', this.$route.params.course_id)
      params.append('stream_id', this.currentItem.id)
      this.updateImage(params).then(() => {
        this.$toasted.success(this.$t('success_updated'))
      })
    },
    formattedDate (date) {
      if (!date) return ''
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    // speakers
    changeInputSpeakers: debounce(function (val) {
      if (!val) return
      if (this.search?.full_name !== val) {
        this.fetchSpeakers({
          keywords: val,
        })
      }
    }, 500),
    autoCompleteChanged (e) {
      this.payload.speakers = e.map(e => e.id)
      this.search = e
    },
    // speakers end //
    setData () {
      const transformObj = {}
      Object.entries(this.currentItem).forEach(e => {
        transformObj[camelCase(e[0])] = e[1]
      })
      Object.assign(this.payload, transformObj)
      this.payload.packages = this.currentItem.packages.data.map(e => e.id)
      this.payload.speakers = this.currentItem.speakers.data.map(e => e.user_position_id)
      this.search = this.payload.speakers
    },
    createRequestPayload () {
      const data = Object.assign(this.payload)
      const formData = new FormData()

      Object.keys(data).forEach(e => {
        if (e !== 'dateAt') {
          formData.append(snakeCase(e), data[e])
        }
      })
      if (this.currentItem) {
        formData.append('id', this.currentItem.id)
        formData.append('_method', 'patch')
      }
      formData.append('course_id', this.$route.params.course_id)
      formData.append('date_at', this.formattedDate(this.payload.dateAt))
      return formData
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        if (this.currentItem) {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'))
          })
        } else {
          this.create(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_created'))
          })
        }
      }
    }
  }
}
